<template>
  <div class="modal text-dark" id="NewCharacterDialog" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="h2 modal-title">New Character</div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-2">
                <label class="form-label">Name</label>
              </div>
              <div class="col-4">
                <input type="text" v-model="name" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" style="width: 100px" data-bs-dismiss="modal" @click="okClicked">
            Ok
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="width: 100px">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from "vue";

const emit = defineEmits(["new-character"]);
const name = ref("");

const okClicked = () => {
  if (name.value) {
    emit("new-character", name.value);
  }
};
</script>
