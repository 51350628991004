<template>
  <div class="container">
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4"><h2>Character View</h2></div>
      <div class="col-4">
        <button class="btn btn-primary" @click="copy">Export</button>
        <button class="btn btn-primary ms-1" @click="clearSpells">Clear Spells</button>
      </div>
    </div>
    <div class="row">
      <BasicInfo :character="character" @dataChanged="dataChanged" />
    </div>
    <div class="row mt-4">
      <div class="col">
        <AbilitiesScores :character="character" @dataChanged="dataChanged" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <TreasureComponent :character="character" @dataChanged="dataChanged" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

import BasicInfo from "../components/BasicInfo.vue";
import AbilitiesScores from "@/components/AbilitiesScores.vue";
import getCurrentCharacter from "@/utils/getCurrentCharacter";
import saveCharacter from "@/utils/saveCharacter";
import TreasureComponent from "@/components/TreasureComponent.vue";

const character = ref({});

const copy = () => {
  navigator.clipboard.writeText(JSON.stringify(character.value));
};

const clearSpells = () => {
  if (character.value && character.value.name) {
    character.value.clericSpellsKnown = [];
    character.value.wizardSpellsKnown = [];
    saveCharacter(character.value, true, "main");
  }
};

const dataChanged = () => {
  storeWindowState();
};

const storeWindowState = () => {
  if (character.value && character.value.name) {
    saveCharacter(character.value, true, "main");
  }
};

onMounted(() => {
  character.value = getCurrentCharacter();
  if (!character.value) {
    character.value = {
      name: "",
      race: "",
      charclass: "",
      level: "",
    };
  }

  window.addEventListener("beforeunload", storeWindowState);
});

onUnmounted(() => {
  storeWindowState();

  window.removeEventListener("beforeunload", storeWindowState);
});
</script>

<style></style>
