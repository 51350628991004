import spellSlots from "../data/wizard-spells-per-level.json";
import getValues from "./getValues";

const getWizardSpellSlots = (level) => {
  if (!level || level == 0)
    return {
      "1st": 0,
      "2nd": 0,
      "3rd": 0,
      "4th": 0,
      "5th": 0,
      "6th": 0,
      "7th": 0,
      "8th": 0,
      "9th": 0,
    };
  return getValues(level, spellSlots);
};

export default getWizardSpellSlots;
