import strValues from "../data/strength.json";
import dexValues from "../data/dexterity.json";
import conValues from "../data/constitution.json";
import intValues from "../data/intelligence.json";
import wisValues from "../data/wisdom.json";
import chaValues from "../data/charisma.json";

import getValues from "./getValues";

const getDexValues = (dex) => {
  return getValues(dex, dexValues);
};

const getConValues = (con) => {
  return getValues(con, conValues);
};

const getIntValues = (int) => {
  return getValues(int, intValues);
};

const getWisValues = (wis) => {
  return getValues(wis, wisValues);
};

const getChaValues = (cha) => {
  return getValues(cha, chaValues);
};

const getStrValues = (str) => {
  if (str == undefined) {
    return undefined;
  }

  let strArray = [];
  let actual = 0;
  if (str.startsWith("18/")) {
    strArray = strValues.percentile;
    let keys = str.split("/");
    actual = parseInt(keys[1]);
    if (keys[1] == "00") {
      actual = "100";
    }
  } else {
    strArray = strValues.normal;
    actual = parseInt(str);
  }

  return getValues(actual, strArray);
};

const getWisBonusSpells = (wis) => {
  if (!wis) return [];

  let wisValues = getWisValues(wis);
  let spells = [];
  while (wis > 0 && wisValues.bonus_spells !== "--") {
    spells.push(wisValues.bonus_spells);
    wis--;
    wisValues = getWisValues(wis);
  }

  return spells.reverse();
};

export { getStrValues, getDexValues, getConValues, getIntValues, getWisValues, getChaValues, getWisBonusSpells };
