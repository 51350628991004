<template>
  <div class="border rounded">
    <h3>Ability Scores</h3>
    <div class="container mb-2">
      <div class="row">
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">STR</label>
        </div>
        <div class="col-1">
          <input type="text" class="form-control col" v-model="character.str" @change="dataChanged()" />
        </div>
        <div class="col-2 mt-2 small text-start">Hit: {{ strValue.hit_prob }}</div>
        <div class="col-2 mt-2 small text-start">Dmg: {{ strValue.damage_adj }}</div>
        <div class="col-3 mt-2 small text-start">Doors: {{ strValue.open_doors }}</div>
        <div class="col-2 mt-2 small text-start">Bars: {{ strValue.bend_bars }}</div>
      </div>
      <div class="row">
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">DEX</label>
        </div>
        <div class="col-1">
          <input type="text" class="form-control col" v-model="character.dex" @change="dataChanged()" />
        </div>
        <div class="col-2 mt-2 small text-start">Reaction: {{ dexValue.reaction }}</div>
        <div class="col-2 mt-2 small text-start">Missile: {{ dexValue.missile }}</div>
        <div class="col-3 mt-2 small text-start">Defense: {{ dexValue.defense }}</div>
      </div>
      <div class="row">
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">CON</label>
        </div>
        <div class="col-1">
          <input type="text" class="form-control col" v-model="character.con" @change="dataChanged()" />
        </div>
        <div class="col-2 mt-2 small text-start">HP: {{ conValue.hp }}</div>
        <div class="col-2 mt-2 small text-start">Sys: {{ conValue.ss }}</div>
        <div class="col-3 mt-2 small text-start">Res Sur: {{ conValue.rs }}</div>
        <div class="col-2 mt-2 small text-start">Poison: {{ conValue.poison }}</div>
      </div>
      <div class="row">
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">INT</label>
        </div>
        <div class="col-1">
          <input type="text" class="form-control col" v-model="character.int" @change="dataChanged()" />
        </div>
        <div class="col-2 mt-2 small text-start">Lang: {{ intValue.lang }}</div>
        <div class="col-2 mt-2 small text-start">Spell Lvl: {{ intValue.spell }}</div>
        <div class="col-3 mt-2 small text-start">% Learn: {{ intValue.learn }}</div>
        <div class="col-2 mt-2 small text-start">Max Lvl: {{ intValue.max }}</div>
      </div>
      <div class="row">
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">WIS</label>
        </div>
        <div class="col-1">
          <input type="text" class="form-control col" v-model="character.wis" @change="dataChanged()" />
        </div>
        <div class="col-2 mt-2 small text-start">Magical Def: {{ wisValue.magical_defense }}</div>
        <div class="col-2 mt-2 small text-start">Failure: {{ wisValue.failure }}</div>
        <div class="col-5 mt-2 small text-start">Bonus Spells: {{ bonusSpells }}</div>
      </div>
      <div class="row">
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">CHA</label>
        </div>
        <div class="col-1">
          <input type="text" class="form-control col" v-model="character.cha" @change="dataChanged()" />
        </div>
        <div class="col-2 mt-2 small text-start">Henchmen: {{ chaValue.henchmen }}</div>
        <div class="col-2 mt-2 small text-start">Loyalty: {{ chaValue.loyalty }}</div>
        <div class="col-3 mt-2 small text-start">Reaction: {{ chaValue.reaction }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, defineEmits, ref, watch, computed } from "vue";
import {
  getStrValues,
  getDexValues,
  getConValues,
  getIntValues,
  getWisValues,
  getChaValues,
  getWisBonusSpells,
} from "@/utils/findAttributeValues";

const emit = defineEmits("dataChanged");

const props = defineProps({
  character: Object,
});

const character = toRef(props, "character");

const strValue = ref({});
const dexValue = ref({});
const conValue = ref({});
const intValue = ref({});
const wisValue = ref({});
const chaValue = ref({});

const updateAttributeValues = () => {
  let strValues = getStrValues(character.value.str);
  if (strValues) {
    strValue.value = strValues;
  }
  let dexValues = getDexValues(character.value.dex);
  if (dexValues) {
    dexValue.value = dexValues;
  }
  let conValues = getConValues(character.value.con);
  if (conValues) {
    conValue.value = conValues;
  }
  let intValues = getIntValues(character.value.int);
  if (intValues) {
    intValue.value = intValues;
  }
  let wisValues = getWisValues(character.value.wis);
  if (wisValues) {
    wisValue.value = wisValues;
  }
  let chaValues = getChaValues(character.value.cha);
  if (chaValues) {
    chaValue.value = chaValues;
  }
};

const occurrencesInArray = (a, s) => {
  let result = 0;
  for (let i = 0; i < a.length; i++) {
    if (a[i] === s) {
      result++;
    }
  }
  return result;
};

const bonusSpells = computed(() => {
  let spells = getWisBonusSpells(character.value.wis);
  if (occurrencesInArray(spells, "1st") == 2) {
    spells.splice(0, 1);
    spells[0] = "2x1st";
    if (occurrencesInArray(spells, "2nd") == 2) {
      spells.splice(1, 1);
      spells[1] = "2x2nd";
    }
  }

  let result = "";
  for (let i = 0; i < spells.length; i++) {
    if (i == spells.length - 1) {
      result += spells[i];
    } else {
      result += spells[i] + ", ";
    }
  }

  return result;
});

watch(character, () => {
  updateAttributeValues();
});

const dataChanged = () => {
  updateAttributeValues();
  emit("dataChanged");
};
</script>

<style></style>
