<template>
  <div class="container justify-content-center">
    <div class="row">
      <h2>Cleric Spells</h2>
    </div>
    <div class="row">
      <div class="col text-end mt-2">
        <label>Caster Level</label>
      </div>
      <div class="col-1 text-start mb-3">
        <input
          type="number"
          name="count"
          id="count"
          min="0"
          max="20"
          class="form-control number-input"
          v-model="casterLevel"
          :onclick="casterLevelChanged"
        />
      </div>
      <div class="col mt-2 text-start">Spell Slots: {{ spellSlotText }}</div>
    </div>
    <div class="row">
      <div class="col-3"></div>
      <button class="btn btn-primary me-2 mb-3 col-3" @click="clearCast">Reset Cast Spells</button>
      <button class="btn btn-primary me-2 mb-3 col-3" @click="clearMemorized">Reset Memorized Spells</button>
    </div>
    <div class="row">
      <div class="col">
        <div class="btn-group mb-3" role="group" aria-label="Basic radio toggle button group">
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio1"
            autocomplete="off"
            :value="ListType.ALL"
            v-model="listType"
          />
          <label class="btn btn-outline-primary" for="btnradio1">All Spells</label>

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio3"
            autocomplete="off"
            :value="ListType.MEMORIZED"
            v-model="listType"
          />
          <label class="btn btn-outline-primary" for="btnradio3">Memorized Spells</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label v-if="listType === 'all'" class="small em mb-3"
          >Click to memorize spell, and right-click to forget</label
        >
        <label v-if="listType === 'memorized'" class="small em mb-3"
          >Click to cast spell, and right-click to uncast spell</label
        >
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <ClericSpellsByLevel :key="forceRender" :level="1" :mode="listType" :slots="spellSlots['1st']" />
      </div>
      <div class="col-4">
        <ClericSpellsByLevel :key="forceRender" :level="2" :mode="listType" :slots="spellSlots['2nd']" />
      </div>
      <div class="col-4">
        <ClericSpellsByLevel :key="forceRender" :level="3" :mode="listType" :slots="spellSlots['3rd']" />
      </div>
      <hr />
      <div class="col-4">
        <ClericSpellsByLevel :key="forceRender" :level="4" :mode="listType" :slots="spellSlots['4th']" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import getCurrentCharacter from "@/utils/getCurrentCharacter";
import saveCharacter from "@/utils/saveCharacter";
import { getClericSpellSlotsAndBonusSpells } from "@/utils/getClericSpellSlots";
import ClericSpellsByLevel from "@/components/ClericSpellsByLevel.vue";

const ListType = {
  ALL: "all",
  MEMORIZED: "memorized",
};

const listType = ref(ListType.ALL);

const casterLevel = ref(0);
const spellSlotText = ref("");
const spellSlots = ref([]);
const forceRender = ref(0);

const character = ref({});

const clearMemorized = () => {
  character.value = getCurrentCharacter();
  if (character.value && character.value.clericSpellsKnown) {
    for (let i = 0; i < character.value.clericSpellsKnown.length; i++) {
      if (character.value.clericSpellsKnown[i]) {
        for (let j = 0; j < character.value.clericSpellsKnown[i].length; j++) {
          character.value.clericSpellsKnown[i][j].memorized = 0;
          character.value.clericSpellsKnown[i][j].cast = 0;
        }
      }
    }
  }
  listType.value = ListType.ALL;
  saveCharacter(character.value, true, "clear-memorized");
  forceRender.value++;
};

const clearCast = () => {
  character.value = getCurrentCharacter();
  if (character.value && character.value.clericSpellsKnown) {
    for (let i = 0; i < character.value.clericSpellsKnown.length; i++) {
      if (character.value.clericSpellsKnown[i]) {
        for (let j = 0; j < character.value.clericSpellsKnown[i].length; j++) {
          character.value.clericSpellsKnown[i][j].cast = 0;
        }
      }
    }
  }

  listType.value = ListType.MEMORIZED;
  saveCharacter(character.value, true, "clear-cast");
  forceRender.value++;
};

const formatSpellSlots = (spellSlots) => {
  let result = "";
  if (spellSlots["1st"] > 0) {
    result += spellSlots["1st"] + " ";
  }
  if (spellSlots["2nd"] > 0) {
    result += "/ " + spellSlots["2nd"];
  }
  if (spellSlots["3rd"] > 0) {
    result += " / " + spellSlots["3rd"];
  }
  if (spellSlots["4th"] > 0) {
    result += " / " + spellSlots["4th"];
  }
  if (spellSlots["5th"] > 0) {
    result += " / " + spellSlots["5th"];
  }
  if (spellSlots["6th"] > 0) {
    result += " / " + spellSlots["6th"];
  }
  if (spellSlots["7th"] > 0) {
    result += " / " + spellSlots["7th"];
  }

  return result;
};

const casterLevelChanged = () => {
  spellSlots.value = getClericSpellSlotsAndBonusSpells(casterLevel.value, character.value.wis);
  spellSlotText.value = formatSpellSlots(spellSlots.value);
  forceRender.value++;
};

onMounted(() => {
  let data = localStorage.getItem("cleric-spell-mode");
  if (data) {
    listType.value = data;
  }
  character.value = getCurrentCharacter();
  casterLevel.value = character.value.clericCasterLevel;
  spellSlots.value = getClericSpellSlotsAndBonusSpells(casterLevel.value, character.value.wis);
  spellSlotText.value = formatSpellSlots(spellSlots.value);
});

onUnmounted(() => {
  localStorage.setItem("cleric-spell-mode", listType.value);
  character.value = getCurrentCharacter();
  character.value.clericCasterLevel = casterLevel.value;
  saveCharacter(character.value, true, "cleric-caster-level");
});
</script>

<style>
ul {
  list-style-type: none;
}
</style>
