import getCharacterByName from "./getCharacterByName";

const getCurrentCharacter = () => {
  let data = localStorage.getItem("currentCharacter");
  if (data) {
    let data2 = getCharacterByName(data);
    if (data2) {
      return data2;
    }
  }

  return undefined;
};

export default getCurrentCharacter;
