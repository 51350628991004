<template>
  <div class="border rounded mb-4">
    <div class="container mb-2 mt-2">
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2 fs-1">Hit Points</label>
        </div>
        <div class="col-3">
          <input
            type="number"
            min="0"
            max="9999"
            v-model="character.current_hit_points"
            class="form-control number-input col fs-1"
            @change="dataChanged()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Wounds</label>
        </div>
        <div class="col-2">
          <input
            type="number"
            min="0"
            max="9999"
            v-model="wounds"
            class="form-control number-input col"
            @change="woundsChanged"
          />
        </div>
        <div class="col-1 me-3">
          <button class="btn btn-primary" data-bs-target="#Damage" data-bs-toggle="modal">Dmg</button>
        </div>
        <div class="col-1 ms-3">
          <button class="btn btn-primary" data-bs-target="#Heal" data-bs-toggle="modal">Heal</button>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Temporary Hit Points</label>
        </div>
        <div class="col-2">
          <input
            type="number"
            min="0"
            max="9999"
            v-model="character.temp_hit_points"
            class="form-control number-input col"
            @change="dataChanged()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Max Hit Points</label>
        </div>
        <div class="col-2">
          <input
            type="number"
            min="0"
            max="9999"
            v-model="character.max_hit_points"
            class="form-control number-input col"
            @change="dataChanged()"
          />
        </div>
      </div>
    </div>
  </div>

  <DamageDialog @damage="doDamage" title="Damage" />
  <DamageDialog @damage="doHeal" title="Heal" />
</template>

<script setup>
import { defineProps, toRef, defineEmits, ref, watch } from "vue";
import DamageDialog from "./DamageDialog.vue";

const emit = defineEmits(["dataChanged"]);

const props = defineProps({
  character: Object,
});

const character = toRef(props, "character");

const wounds = ref(0);

const woundsChanged = () => {
  let delta = wounds.value - character.value.wounds;

  // take wounds off temp hit points first
  if (delta > 0 && character.value.temp_hit_points) {
    if (delta > character.value.temp_hit_points) {
      delta -= character.value.temp_hit_points;
      character.value.temp_hit_points = 0;
    } else {
      character.value.temp_hit_points -= delta;
      delta = 0;
    }
  }

  character.value.wounds += delta;
  dataChanged();
};

watch(props, () => {
  wounds.value = character.value.wounds;
});

const doDamage = (d) => {
  wounds.value += d;
  woundsChanged();
};

const doHeal = (d) => {
  wounds.value -= d;
  if (wounds.value < 0) {
    wounds.value = 0;
  }
  woundsChanged();
};

const dataChanged = () => {
  if (!character.value.wounds) character.value.wounds = 0;
  if (!character.value.temp_hit_points) character.value.temp_hit_points = 0;
  if (!character.value.max_hit_points) character.value.max_hit_points = 0;
  if (!character.value.current_hit_points) character.value.current_hit_points = 0;

  character.value.current_hit_points =
    parseInt(character.value.max_hit_points) -
    parseInt(character.value.wounds) +
    parseInt(character.value.temp_hit_points);
  emit("dataChanged");
};
</script>

<style></style>
