import { createRouter, createWebHistory } from "vue-router";
import MainView from "../views/MainView.vue";
import RootView from "../views/RootView.vue";
import ClericSpells from "../views/ClericSpells";
import WizardSpells from "../views/WizardSpells";
import CombatView from "../views/CombatView";
import getCurrentCharacter from "@/utils/getCurrentCharacter";
// import { auth } from "../firebase/config";

const requiredAuth = (to, from, next) => {
  if (!getCurrentCharacter()) {
    next({ name: "RootView" });
  } else {
    next();
  }
  /*  let user = auth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }*/
};

/*
const requiredNoAuth = (to, from, next) => {
  let user = auth.currentUser;
  if (user) {
    next({ name: "Home" });
  } else {
    next();
  }
};
*/
const routes = [
  {
    path: "/",
    name: "Root",
    component: RootView,
    // beforeEnter: requiredAuth,
  },
  {
    path: "/home",
    name: "Home",
    component: MainView,
    beforeEnter: requiredAuth,
  },
  {
    path: "/combatview",
    name: "CombatView",
    component: CombatView,
    beforeEnter: requiredAuth,
  },
  {
    path: "/clericspells",
    name: "ClericSpells",
    component: ClericSpells,
    beforeEnter: requiredAuth,
  },
  {
    path: "/wizardspells",
    name: "WizardSpells",
    component: WizardSpells,
    beforeEnter: requiredAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
