const getValues = (actual, valuesArray) => {
  for (let i = 0; i < valuesArray.length; i++) {
    let checking = parseInt(valuesArray[i].value);

    if (actual <= checking) {
      return { ...valuesArray[i] };
    }
  }
  return { ...valuesArray[valuesArray.length - 1] };
};

export default getValues;
