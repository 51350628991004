<template>
  <div class="border rounded">
    <div class="container mb-2 mt-2">
      <div class="row">
        <div class="col-6 text-end fs-1">
          <label for="strInput" class="form-label mt-2">Armor Class</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control fs-1 col" v-model="character.armor_class" readonly />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Surprised AC</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.surprised_ac" readonly />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Shieldless AC</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.shieldless_ac" readonly />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Rear AC</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.rear_ac" readonly />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end"></div>
        <div class="col-5">
          <label class="form-label">Item</label>
        </div>
        <div class="col-2">
          <label class="form-label">Value</label>
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end">
          <label for="strInput" class="form-label mt-2">Armor Worn</label>
        </div>
        <div class="col-5">
          <input type="text" class="form-control col" v-model="character.armor_description" @change="dataChanged()" />
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.armor_value" @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end">
          <label for="strInput" class="form-label mt-2">Shield</label>
        </div>
        <div class="col-5">
          <input type="text" class="form-control col" v-model="character.shield_description" @change="dataChanged()" />
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.shield_value" @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end">
          <label for="strInput" class="form-label mt-2">Armor Mods (all)</label>
        </div>
        <div class="col-5">
          <input
            type="text"
            class="form-control col"
            v-model="character.armor_mod_description"
            @change="dataChanged()"
          />
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.armor_mod_value" @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end">
          <label for="strInput" class="form-label mt-2">Dexterity</label>
        </div>
        <div class="col-5"></div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="dexMod" readonly />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, defineEmits, computed } from "vue";
import { getDexValues } from "@/utils/findAttributeValues";

const emit = defineEmits(["dataChanged"]);

const props = defineProps({
  character: Object,
});

const character = toRef(props, "character");

const dexMod = computed(() => {
  let dexValues = getDexValues(character.value.dex);
  if (dexValues) {
    return parseInt(dexValues.defense) * -1;
  }
  return 0;
});

const dataChanged = () => {
  if (!character.value.armor_value) character.value.armor_value = 0;
  if (!character.value.shield_value) character.value.shield_value = 0;
  if (!character.value.armor_mod_value) character.value.armor_mod_value = 0;

  character.value.armor_class =
    10 +
    dexMod.value +
    parseInt(character.value.armor_value) +
    parseInt(character.value.shield_value) +
    parseInt(character.value.armor_mod_value);
  character.value.surprised_ac =
    10 +
    parseInt(character.value.armor_value) +
    parseInt(character.value.shield_value) +
    parseInt(character.value.armor_mod_value);
  character.value.shieldless_ac =
    10 + dexMod.value + parseInt(character.value.armor_value) + parseInt(character.value.armor_mod_value);

  character.value.rear_ac = character.value.surprised_ac;
  emit("dataChanged");
};
</script>

<style></style>
