<template>
  <div class="modal text-dark" :id="title" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="h2 modal-title">{{ title }}</div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-5"></div>
              <div class="col-2">
                <input type="number" min="1" max="9999" v-model="wounds" class="form-control number-input" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" style="width: 100px" data-bs-dismiss="modal" @click="okClicked">
            Ok
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            style="width: 100px"
            @click="cancelClicked"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, defineProps, toRef } from "vue";

const props = defineProps({
  title: String,
});

const title = toRef(props, "title");

const emit = defineEmits(["damage"]);
const wounds = ref(1);

const okClicked = () => {
  emit("damage", wounds.value);
};

const cancelClicked = () => {};
</script>
