import w1 from "../data/W1.json";
import w2 from "../data/W2.json";
import w3 from "../data/W3.json";
import w4 from "../data/W4.json";
import w5 from "../data/W5.json";
import w6 from "../data/W6.json";

const findWizardSpellsPerLevel = (level) => {
  switch (level) {
    case 1:
      return w1;
    case 2:
      return w2;
    case 3:
      return w3;
    case 4:
      return w4;
    case 5:
      return w5;
    case 6:
      return w6;
    default:
      return [];
  }
};

const findWizardSpell = (level, name) => {
  let spellArray = [];
  switch (level) {
    case 1:
      spellArray = w1;
      break;
    case 2:
      spellArray = w2;
      break;
    case 3:
      spellArray = w3;
      break;
    case 4:
      spellArray = w4;
      break;
    case 5:
      spellArray = w5;
      break;
    case 6:
      spellArray = w6;
      break;
    default:
      return [];
  }

  for (let i = 0; i < spellArray.length; i++) {
    if (name === spellArray[i].name) {
      return spellArray[i];
    }
  }
};

export { findWizardSpell, findWizardSpellsPerLevel };
