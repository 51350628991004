<template>
  <h2>Combat View</h2>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <ArmorClass :character="character" @dataChanged="dataChanged" />
      </div>
      <div class="col-6">
        <HitPoints :character="character" @dataChanged="dataChanged" />
        <SavingThrows :character="character" @dataChanged="dataChanged" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import SavingThrows from "@/components/SavingThrows.vue";
import saveCharacter from "@/utils/saveCharacter";
import getCurrentCharacter from "@/utils/getCurrentCharacter";
import ArmorClass from "@/components/ArmorClass.vue";
import HitPoints from "@/components/HitPoints.vue";

const character = ref({});

const dataChanged = () => {
  storeWindowState();
};

const storeWindowState = () => {
  if (character.value && character.value.name) {
    saveCharacter(character.value, true, "combat");
  }
};

onMounted(() => {
  character.value = getCurrentCharacter();
  if (!character.value) {
    character.value = {
      name: "",
      race: "",
      charclass: "",
      level: "",
    };
  }

  window.addEventListener("beforeunload", storeWindowState);
});

onUnmounted(() => {
  storeWindowState();

  window.removeEventListener("beforeunload", storeWindowState);
});
</script>

<style></style>
