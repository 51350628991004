<template>
  <header class="app-header">
    <NavBar></NavBar>
  </header>
  <main class="container">
    <router-view></router-view>
  </main>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  text-align: center;
  color: white;
  max-width: 1200px;
  background: #212529;
}

html,
body {
  background: #212529;
  --bs-body-bg: #212529;
}
</style>
