import c1 from "../data/C1.json";
import c2 from "../data/C2.json";
import c3 from "../data/C3.json";
import c4 from "../data/C4.json";

const findClericSpellsPerLevel = (level) => {
  switch (level) {
    case 1:
      return c1;
    case 2:
      return c2;
    case 3:
      return c3;
    case 4:
      return c4;
    default:
      return [];
  }
};

const findClericSpell = (level, name) => {
  let spellArray = [];
  switch (level) {
    case 1:
      spellArray = c1;
      break;
    case 2:
      spellArray = c2;
      break;
    case 3:
      spellArray = c3;
      break;
    case 4:
      spellArray = c4;
      break;
    default:
      return [];
  }

  for (let i = 0; i < spellArray.length; i++) {
    if (name === spellArray[i].name) {
      return spellArray[i];
    }
  }
};

export { findClericSpell, findClericSpellsPerLevel };
