<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <label for="nameInput" class="form-label align-middle">Name</label>
        <input type="text" class="form-control col" v-model="character.name" @change="dataChanged()" />
      </div>
      <div class="col">
        <label for="raceInput" class="form-label align-middle">Race</label>
        <input type="text" class="form-control col" v-model="character.race" @change="dataChanged()" />
      </div>
      <div class="col">
        <label for="classInput" class="form-label align-middle">Class</label>
        <input type="text" class="form-control col" v-model="character.charclass" @change="dataChanged()" />
      </div>
      <div class="col">
        <label for="levelInput" class="form-label align-middle">Level</label>
        <input type="text" class="form-control col" v-model="character.level" @change="dataChanged()" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, defineEmits } from "vue";

const emit = defineEmits(["dataChanged"]);

const props = defineProps({
  character: Object,
});

const character = toRef(props, "character");

const dataChanged = () => {
  emit("dataChanged");
};
</script>

<style></style>
