<template>
  <div class="border rounded">
    <h3>Saving Throws</h3>
    <div class="container mb-2">
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Paralyzation/Poison/Death</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.deathSave" @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Rod, Staff, or Wand</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.rodSave" @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Petrification/Polymorph</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.petrificationSave" @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Breath Weapon</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.breathSave" @change="dataChanged()" />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-end">
          <label for="strInput" class="form-label mt-2">Spell</label>
        </div>
        <div class="col-2">
          <input type="text" class="form-control col" v-model="character.spellSave" @change="dataChanged()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, defineEmits } from "vue";

const emit = defineEmits("dataChanged");

const props = defineProps({
  character: Object,
});

const character = toRef(props, "character");

const dataChanged = () => {
  emit("dataChanged");
};
</script>

<style></style>
