const getCharacterByName = (name) => {
  let data = localStorage.getItem("character-" + name);
  if (data) {
    return JSON.parse(data);
  }

  return undefined;
};

export default getCharacterByName;
