<template>
  <div v-if="mode === 'all' || (mode === 'known' && spell.known) || (mode === 'memorized' && spell.memorized > 0)">
    <div class="container">
      <div class="row">
        <div class="col-3" @click="divClick" @contextmenu.prevent="onRightClick">
          <div class="text-end">
            <i v-for="index in toCast" :key="index" class="bi bi-circle"></i>
            <i v-for="index in spell.cast" :key="index" class="bi bi-check2-circle"></i>
          </div>
        </div>
        <div class="col text-start" @contextmenu.prevent>
          <i :style="[spell.known ? { color: 'white' } : { color: 'transparent' }]" class="bi bi-book me-2"></i>
          <a class="" data-bs-toggle="modal" v-bind:data-bs-target="'#' + spell.id" role="button">
            {{ spell.name }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    :id="spell.id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ spell.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body text-dark container">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-5">
              <h2>{{ spell.name }}</h2>
            </div>
            <div class="col-5 text-end">
              <h2>Level: {{ spell.level }}</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-5">({{ spell.schools.join(", ") }})</div>
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-5">Range: {{ spell.range }}</div>
            <div class="col-5 text-end">Components: {{ spell.components }}</div>
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-5">Duration: {{ spell.duration }}</div>
            <div class="col-5 text-end">Casting Time: {{ spell.castingTime }}</div>
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-5">Area of Effect: {{ spell.areaOfEffect }}</div>
            <div class="col-5 text-end">Saving Throw: {{ spell.savingThrow }}</div>
          </div>

          <ul class="mt-4">
            <li v-for="item in spell.description" :key="item">
              <p>{{ item }}</p>
            </li>
          </ul>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, toRef, computed } from "vue";

const props = defineProps({
  spell: Object,
  mode: String,
  availSpellSlots: Number,
});

const emit = defineEmits(["update-spell", "know-spell", "unknow-spell"]);

const spell = toRef(props, "spell");
const mode = toRef(props, "mode");
// const availSpellSlots = toRef(props, "availSpellSlots");

const toCast = computed(() => {
  if (!spell.value.memorized) return 0;
  if (!spell.value.cast) return spell.value.memorized;
  return spell.value.memorized - spell.value.cast;
});

const onRightClick = () => {
  if (mode.value === "known" && spell.value.memorized) {
    spell.value.memorized--;
    if (spell.value.memorized < 0) {
      spell.value.memorized = 0;
    }
  } else if (mode.value === "memorized" && spell.value.cast) {
    spell.value.cast--;
    if (spell.value.cast < 0) {
      spell.value.cast = 0;
    }
  }
  emit("update-spell", spell);
};

const divClick = () => {
  if (mode.value === "all" && !spell.value.known) {
    spell.value.known = true;
    if (spell.value.reversible) {
      emit("know-spell", spell.value.reversible);
    }
  } else if (mode.value === "all" && spell.value.known) {
    spell.value.known = false;
    if (spell.value.reversible) {
      emit("unknow-spell", spell.value.reversible);
    }
  } else if (mode.value === "known" && !spell.value.memorized) {
    spell.value.memorized = 1;
  } else if (mode.value === "known") {
    spell.value.memorized++;
    if (spell.value.memorized > 5) {
      spell.value.memorized = 5;
    }
  } else if (mode.value === "memorized") {
    if (!spell.value.cast) {
      spell.value.cast = 1;
    } else {
      spell.value.cast++;
    }
    if (spell.value.cast > spell.value.memorized) {
      spell.value.cast = spell.value.memorized;
    }
  }
  emit("update-spell", spell);
};
</script>

<style></style>
