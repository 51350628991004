<template>
  <div class="border rounded mb-4">
    <h3>Treasure</h3>
    <div class="container mb-2 mt-2">
      <div class="row">
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">CP</label>
        </div>
        <div class="col-2">
          <input
            type="number"
            min="0"
            max="999999"
            v-model="character.cp"
            class="form-control number-input col"
            @change="dataChanged()"
          />
        </div>
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">SP</label>
        </div>
        <div class="col-2">
          <input
            type="number"
            min="0"
            max="999999"
            v-model="character.sp"
            class="form-control number-input col"
            @change="dataChanged()"
          />
        </div>
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">GP</label>
        </div>
        <div class="col-2">
          <div>
            <input
              type="number"
              min="0"
              max="999999"
              v-model="character.gp"
              class="form-control number-input col"
              @change="dataChanged()"
            />
          </div>
        </div>
        <div class="col-1 text-end">
          <label for="strInput" class="form-label mt-2">PP</label>
        </div>
        <div class="col-2">
          <input
            type="number"
            min="0"
            max="999999"
            v-model="character.pp"
            class="form-control number-input col"
            @change="dataChanged()"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-5"></div>
        <div class="col-1">
          <button class="btn btn-primary">Add</button>
        </div>
        <div class="col-1">
          <button class="btn btn-primary">Spend</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, defineEmits } from "vue";

const emit = defineEmits(["dataChanged"]);

const props = defineProps({
  character: Object,
});

const character = toRef(props, "character");

const dataChanged = () => {
  emit("dataChanged");
};
</script>

<style></style>
