<template>
  <div class="container">
    <div class="row">
      <div class="col-3"></div>
      <div class="h4 col">{{ levelName() }} Level</div>
    </div>
    <div class="row mb-2" v-if="mode === 'all'">
      <div class="col-3"></div>
      <div class="col">Open slots: {{ slots - spellCount }}</div>
    </div>
    <div class="row text-start" v-for="spell in spells" v-bind:key="spell.name">
      <ClericSpellInfo :spell="spell" :mode="mode" :memorized="memorized" @update-spell="(e) => updateSpell(e)" />
    </div>
  </div>
</template>

<script setup>
import getCurrentCharacter from "@/utils/getCurrentCharacter";
import saveCharacter from "@/utils/saveCharacter";
import { defineProps, toRef, ref, onMounted, watch } from "vue";
import ClericSpellInfo from "./ClericSpellInfo.vue";
import { findClericSpellsPerLevel } from "@/utils/findClericSpells";

const props = defineProps({
  level: Number,
  mode: String,
  slots: Number,
});

const mode = toRef(props, "mode");
const level = toRef(props, "level");
const slots = toRef(props, "slots");

const spellCount = ref(0);

const spells = ref([]);

const memorized = ref(false);

const character = ref(getCurrentCharacter());

watch(props, () => {
  buildSpellsList();
});

const levelName = () => {
  switch (level.value) {
    case 1:
      return "1st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    case 4:
      return "4th";
    case 5:
      return "5th";
    case 6:
      return "6th";
    case 7:
      return "7th";
  }
};

const persistKnownSpells = () => {
  saveCharacter(character.value, true, "spell");
  buildSpellsList();
};

const updateSpell = (spell) => {
  character.value = getCurrentCharacter();

  if (!character.value.clericSpellsKnown) {
    character.value.clericSpellsKnown = [];
  }
  if (!character.value.clericSpellsKnown[level.value]) {
    character.value.clericSpellsKnown[level.value] = [];
  }

  let index = findIndexOfSpellByName(spell.value.name, character.value.clericSpellsKnown[level.value]);
  if (index == -1) {
    character.value.clericSpellsKnown[level.value].push(spell.value);
  } else {
    character.value.clericSpellsKnown[level.value][index] = spell.value;
  }

  persistKnownSpells();
};

const buildSpellsList = () => {
  spells.value = findClericSpellsPerLevel(level.value);

  spellCount.value = 0;

  if (character.value && character.value.clericSpellsKnown && character.value.clericSpellsKnown[level.value]) {
    for (let i = 0; i < spells.value.length; i++) {
      let spell = findSpellByName(spells.value[i].name, character.value.clericSpellsKnown[level.value]);
      if (spell) {
        spells.value[i] = spell;
        if (spell.memorized) {
          spellCount.value += spell.memorized;
        }
      }
    }
  }
};

const findIndexOfSpellByName = (name, spellArray) => {
  if (!name || !spellArray) return -1;

  for (let i = 0; i < spellArray.length; i++) {
    if (spellArray[i].name && name === spellArray[i].name) {
      return i;
    }
  }

  return -1;
};

const findSpellByName = (name, spellArray) => {
  if (!name || !spellArray) return undefined;

  for (let i = 0; i < spellArray.length; i++) {
    if (spellArray[i].name && name === spellArray[i].name) {
      return spellArray[i];
    }
  }

  return undefined;
};

onMounted(() => {
  buildSpellsList();
});
</script>

<style></style>
