<template>
  <div class="container">
    <div class="row">
      <h2>Characters</h2>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <button class="btn btn-primary me-2" @click="loadSelectedCharacter">Load</button>
        <button class="btn btn-primary me-2" data-bs-target="#NewCharacterDialog" data-bs-toggle="modal">New</button>
        <button class="btn btn-danger me-2" @click="deleteSelectedCharacter">Delete</button>
        <button class="btn btn-primary me-2" data-bs-target="#ImportCharacterDialog" data-bs-toggle="modal">
          Import
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-3"></div>
      <div class="char-list bg-light text-dark rounded mt-2 text-start py-2 col-6">
        <div class="list-group container">
          <div class="mx-3 list-group-item bg-light border-0 my-0 py-1 row" v-for="c in characters" :key="c">
            <a
              href="#"
              @click="selected = c"
              class="list-group-item list-group-item-action border-0 p-0 ps-2 col-2"
              :class="{ 'active rounded': selected && selected === c }"
            >
              {{ c }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <NewNameDialog @new-character="(name) => newCharacter(name)" />
  <ImportCharacterDialog @import-character="(data) => importCharacter(data)" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import NewNameDialog from "@/components/NewNameDialog.vue";
import { useRouter } from "vue-router";
import ImportCharacterDialog from "@/components/ImportCharacterDialog.vue";

const router = useRouter();

const characters = ref([]);
const selected = ref(null);

const importCharacter = (data) => {
  if (data) {
    let c = JSON.parse(data);
    if (c && c.name) {
      localStorage.setItem("character-" + c.name, data);
      localStorage.setItem("currentCharacter", c.name);
      router.push("/home");
    }
  }
};

const deleteSelectedCharacter = () => {
  if (selected.value) {
    localStorage.removeItem("currentCharacter");
    localStorage.removeItem("character-" + selected.value);
    selected.value = null;
    loadCharacterList();
  }
};

const loadSelectedCharacter = () => {
  if (selected.value) {
    localStorage.setItem("currentCharacter", selected.value);
    router.push("/home");
  }
};

const newCharacter = (name) => {
  let obj = { name: name };
  localStorage.setItem("character-" + name, JSON.stringify(obj));
  localStorage.setItem("currentCharacter", name);
  router.push("/home");
};

const loadCharacterList = () => {
  characters.value = [];
  for (var key in localStorage) {
    if (key.startsWith("character-")) {
      characters.value.push(key.substring(10));
    }
  }
};

onMounted(() => {
  loadCharacterList();
  let d = localStorage.getItem("currentCharacter");
  if (d) {
    selected.value = d;
  }
});
</script>

<style scoped>
.list-group {
  max-height: 620px;
  overflow: scroll;
}

.char-list {
  height: 620px;
}
</style>
