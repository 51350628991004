import spellSlots from "../data/cleric-spells-per-level.json";
import getValues from "./getValues";
import { getWisBonusSpells } from "./findAttributeValues";

const getClericSpellSlots = (level) => {
  if (!level || level == 0)
    return {
      "1st": 0,
      "2nd": 0,
      "3rd": 0,
      "4th": 0,
      "5th": 0,
      "6th": 0,
      "7th": 0,
    };
  return getValues(level, spellSlots);
};

const getClericSpellSlotsAndBonusSpells = (level, wis) => {
  let values = getClericSpellSlots(level);

  if (values["1st"] > 0 && wis) {
    if (values) {
      let wisValues = getWisBonusSpells(wis);
      if (!wisValues) {
        return {};
      }

      for (let i = 0; i < wisValues.length; i++) {
        if (values[wisValues[i]] > 0) {
          values[wisValues[i]]++;
        }
      }
    }
  }

  return values;
};

export { getClericSpellSlots, getClericSpellSlotsAndBonusSpells };
